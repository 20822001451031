/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import axios from 'axios'
import md5 from 'js-md5'
import store from '../store'

const _ = require('lodash')

let messageList = [];
let urlList = []
const showMessage = _.debounce(() => {
  if (messageList.length > 0) {
    if (messageList[0].type === 1) {
      window.v.$message.success(messageList[0].message)
    } else if (messageList[0].type === 2) {
      let time = 0
      messageList[0].message.forEach((item) => {
        setTimeout(() => {
          window.v.$notify.error({
            title: '提示',
            message: item,
          })
        }, time)
        time += 500
      })
      /* v.$notify.error({
        title: '提示',
        message: messageList[0].message
      }); */
    } else if (messageList[0].type === 3) {
      window.v.$message.error(messageList[0].message);
    } else if (messageList[0].type === 4) {
      // window.v.$message.error(`请求过于频繁，请稍后再试`);
    }
    messageList.splice(0, 1);
  }
}, 300);
const pushMessage = () => {
  messageList = messageList.filter((item, index) => index === 0 || (index !== 0 && JSON.stringify(item) !== JSON.stringify(messageList[0])))
  showMessage();
};

function http({ store, isloaded = false }) {
  /**
   * 1： 成功,
   * 2，notify-error,
   * 3: 失败提醒
   */
  /* eslint-disable */
  // http请求设置
  axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
  if (isloaded) {return axios}
  // 请求前配置  去首位空格
  var instanceRequest = axios.interceptors.request.use(config => {
      urlList.push(config.url)
      let requestData = '' // 防串改 参数集合
      try {
        if (config.headers['Content-Type'] === 'application/json;charset=UTF-8' || config.headers['content-type'] === 'application/json;charset=UTF-8') {
          let data = JSON.parse(JSON.stringify(config.data))
          if (config.method === 'get') {
            // get请求
            try {
              if (!config.params.venueId) {
                config.params.venueId = store.state.venueId
              }
            } catch(e) {}
            if (config.params) {
              for (let i in config.params) {
                requestData += `${i}=${config.params[i]}&`
              }
              requestData = requestData.replace(/&$/, '')
            } else {
              let num = config.url.indexOf('?')
              requestData = num > -1 ? config.url.substr(num + 1) : null
            }
          } else {
            if (typeof data === 'string') {
              data = JSON.parse(data)
            }
            if (typeof data === 'object') {
              try {
                if (!data.venueId) {
                  data.venueId = store.state.venueId
                }
              } catch(e) {}
              let arr = Object.keys(data)
              if (arr.length > 0) {
                arr = arr.sort(function(string1, string2) {
                  let arrList = []
                  let num = string1.length > string2.length ? string1.length : string2.length
                  for (var i = 0; i < num; i++) {
                    var val1 = string1[i] || ''
                    var val2 = string2[i] || ''
                    if (val1 < val2) {
                      arrList.push(false)
                    } else if (val1 > val2) {
                      arrList.push(true)
                    }
                  }
                  return arrList[0] ? 1 : -1
                })
              }
              let newData = {}
              for (let i in arr) {
                newData = {...newData, ...{[arr[i]]: data[arr[i]]}}
              }
              data = newData
              for (let i in data) {
                if (typeof data[i] === 'string') {
                  data[i] = data[i].replace(/(^\s*)|(\s*$)/g, '')
                }
              }
            }
            requestData = JSON.stringify(data)
            config.data = data
          }
        } else if (config.headers['Content-Type'].indexOf('multipart/form-data') > -1) {
          const obj = new Object()
          /* form-data 格式 获取参数 */
          config.data.forEach((value, key) => {
            if (typeof value !== 'object') {
              obj[key] = typeof value === 'number' ? String(value) : value
            }
          })
          let arr = Object.keys(obj)
          if (arr.length > 0) {
            arr = arr.sort(function(string1, string2) {
              let arrList = []
              let num = string1.length > string2.length ? string1.length : string2.length
              for (var i = 0; i < num; i++) {
                var val1 = string1[i] || ''
                var val2 = string2[i] || ''
                if (val1 < val2) {
                  arrList.push(false)
                } else if (val1 > val2) {
                  arrList.push(true)
                }
              }
              return arrList[0] ? 1 : -1
            })
            const sortData = {}
            arr.forEach(item => {
              sortData[item] = obj[item]
            })
            requestData = JSON.stringify(sortData)
          }
        }
      } catch (e) {}
      const timestamp = new Date().getTime() + Number(store.state.timestampDiff)
      const SIGN = store.state.TOKEN + '&' + (Boolean(requestData) && requestData !== '{}' ? timestamp + '&' + requestData : timestamp)
      config.headers['X-Sign'] = md5(SIGN)
      config.headers['X-Timestamp'] = timestamp
      if (store.state.TOKEN) {
        config.headers['token'] = store.state.TOKEN
      }
      return config
  })
  // 过滤响应
  var instanceResponse = axios.interceptors.response.use(result => {
    if (result.config) {
      urlList = urlList.filter(item => {
        return item !== result.config.url
      })
    }
    /* result 为服务器响应回来的数据 */
    /* 方式一：直接返回处理后的 result */
    if (result.data.code === 400) {
      /* 方式二：返回一个 Promise 对象 可以根据服务端返回的错误码进行判断 */
      // let time = 0
      messageList.push({
        type: 2,
        message: result.data.data
      })
      pushMessage()
    } else if (result.data.code === 0 && result.data.msg.trim() !== 'success') {
      messageList.push({
        type: 1,
        message: result.data.msg
      })
      pushMessage()
      // v.$message()
    } else if (result.data.code === 302 || result.data.code === 401) {
      // v.$message.error(result.data.msg)
      sessionStorage.clear()
      store.commit('setName', '')
      store.commit('setUserName', '')
      store.commit('setUserId', '')
      store.commit('setAuthority', [])
      v.$router.push('/login')
      messageList = []
      messageList.push({
        type: 3,
        message: result.data.msg
      })
      pushMessage()
    } else if (result.data.msg && result.data.msg.trim() !== '' && result.data.code !== 0 && result.data.code !== 402) {
      messageList.push({
        type: 3,
        message: result.data.msg
      })
      pushMessage()
      // v.$message.error(result.data.msg)
    }
    return result
  }, err => {
    /* 当响应出错的时候 */
    if (err.response.status === 401) {
      sessionStorage.clear()
      store.commit('setName', '')
      store.commit('setUserName', '')
      store.commit('setUserId', '')
      store.commit('setAuthority', [])
      v.$router.push('/login')
      messageList.push({
        type: 3,
        message: '登录超时，请重新登录'
      })
      pushMessage()
    } else if (err.response.status === 403) {
      messageList.push({
        type: 3,
        message: '无权进行此操作'
        // message: err.response.data.msg
      })
      pushMessage()
    }
    // v.$message.error('无权进行此操作')
    return Promise.reject(err)
  })
  return axios
}
export default http({ store, isloaded: false })
