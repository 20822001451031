/*
 * @Author: 林娴
 * @LastEditors: 林娴
 */
export default {
    install(Vue) {
      // 防重复点击(指令实现)
      //使用： 在需要的按钮中加 v-repeatClick 指令即可
      Vue.directive('repeatClick', {
        inserted(el, binding) {
          el.addEventListener('click', () => {
            if (!el.disabled) {
              el.disabled = true
              setTimeout(() => {
                el.disabled = false
              }, binding.value || 1500)
            }
          })
        },
      })
      // 输入查询(指令实现)
      // 使用： 在需要的按钮中加 v-searchInput 指令，值为{fn: 查询函数, time: 默认查询时间}
      Vue.directive('searchInput', {
        inserted(el, binding) {
          if (binding.value.timer) return
          el.addEventListener('input', () => {
            binding.value.timer = setTimeout(() => {
              (binding.value.fn)()
            }, 1000)
          })
        },
      })
    },
}
