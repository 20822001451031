<template>
  <div id="app">
    <audio ref="audio" muted id="audio" loop="loop" src="./tip.mp3"></audio>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'app',
  computed: {
    ...mapState(['authority', 'SystemName', 'SystemIcon']),
  },
  methods: {
    ...mapActions({
      setConfig: 'setConfig',
      setDict: 'setDict',
      setButtonAuth: 'setButtonAuth',
    }),
    openWebsocket() {
      /* const baseUrl = window.location.host
      const index = baseUrl.indexOf('http://')
      const index1 = baseUrl.indexOf('https://') */
      /* let url
      if (index > -1) {
        url = baseUrl.slice(index + 7)
      } else if (index1 > -1) {
        url = baseUrl.slice(index1 + 8)
      } else {
        url = baseUrl
      } */
      // const wsServer = `ws://${url}?userId=${this.USERID}` // 服务器地址
      const wsServer = process.env.NODE_ENV === 'development' ? `ws:/${window.location.host}:9527/wsapi/websocket/2` : `wss://zhcg.one-info.cn/wsapi/websocket/2` // 服务器地址
      // const wsServer = `ws://${url}?userId=${this.USERID}` // 服务器地址
      // const wsServer = `ws://192.168.10.3:8001`
      const websocket = new WebSocket(wsServer) // 创建WebSocket对象
      websocket.onmessage = (evt) => {
        this.getMessage(evt)
      }
    },
    getMessage(evt) {
      if (JSON.parse(evt.data)) {
        const data = JSON.parse(evt.data)
        this.$refs.audio.play()
        this.$notify.error({
          title: `设备告警`,
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: `告警设备：${data.deviceAddr} ${data.deviceNm}<br/> 告警时间：${data.alarmTime} <br/> 告警内容：${data.alarmContent}`,
          onClose: () => {
            this.$refs.audio.pause()
          },
        });
      }
    },
  },
  // watch: {
  //   $route(to) {
  //     const map = (item) => {
  //       item.children.forEach((child) => {
  //         if (child.path !== to.fullPath) {
  //           if (child.children && child.children.length > 0) {
  //             map(child)
  //           }
  //         } else {
  //           this.setButtonAuth(child.button)
  //           sessionStorage.setItem('buttonAuth', JSON.stringify(child.button))
  //         }
  //       })
  //     }
  //     this.authority.forEach((item) => {
  //       if (item.path !== to.fullPath) {
  //         if (item.children && item.children.length > 0) {
  //           map(item)
  //         }
  //       } else {
  //         this.setButtonAuth(item.button)
  //         sessionStorage.setItem('buttonAuth', JSON.stringify(item.button))
  //       }
  //     })
  //   },
  // },
  mounted() {
    // this.openWebsocket()
    // this.getMessage({ data: "{\"alarmContent\":\"烟雾报警\",\"alarmTime\":\"2019-12-28 12:05:15\",\"deviceAddr\":\"华东地区\",\"deviceID\":\"174035715721\",\"deviceNm\":\"烟雾174035715721\"}" })
    document.title = this.SystemName
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    console.log(this.SystemIcon, 'SystemIcon')
    link.href = this.SystemIcon;
    document.getElementsByTagName('head')[0].appendChild(link);
  },
}
</script>

<style>
#app {
  margin: 0;
  border: 0;
  padding: 0;
  height: 100vh;
  overflow-y: hidden;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.menu{
  border-right: 0;
}
</style>
