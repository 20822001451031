<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
  <div class="memberdialog">
    <el-dialog
      title="会员卡详情"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="927px">
      <div>
        <el-row class="line">
          <el-col :span="5">
            <img v-if="data.memberPersonalInfo.avatar" :src="data.memberPersonalInfo.avatar" class="img" alt="">
            <div class="imgbox" v-else></div>
          </el-col>
          <el-col :span="6">
            <div>
              会员姓名：<span>{{data.memberPersonalInfo.memberName}}</span>
            </div>
            <div>
              联系电话：<span>{{data.memberPersonalInfo.telephone}}</span>
            </div>
            <div>
              发行商户：<span>{{data.memberPersonalInfo.merchantName}}</span>
            </div>
          </el-col>
          <el-col :span="7">
            <div>
              会员卡种类：<span>{{data.cardTypeName}}</span>
            </div>
            <div>
              会员卡号：<span>
                {{data.cardNo}}
              </span>
            </div>
            <div>
              身份证号：<span>{{data.memberPersonalInfo.idCardNo}}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              会员卡名称：<span>{{data.name}}</span>
            </div>
            <div>
              会员卡状态：<span :class="data.cardStatusName === '正常使用' ? '' : 'red'">
                {{data.cardStatusName}}
              </span>
            </div>
          </el-col>
        </el-row>
        <el-row class="second">
          <el-col :span="6">
            <div>
              购卡时间：<span>
                {{data.buyTime}}
              </span>
            </div>
            <div>
              购买天数：<span>
                {{data.buyDays}}天
              </span>
            </div>
            <div v-if="data.cardType === 2">
              可用次数：<span>
                {{data.availableTicketQty}}次
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              可用场馆：<span v-for="(item, index) in data.venueList" :key="index">{{item.venueName}}<span v-if="index < data.venueList.length - 1">/</span></span>
            </div>
            <div v-if="data.cardType === 2">
              赠送次数：<span>
                {{data.giveTicketQty}}次
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              开卡时间：<span>
                {{data.activeTime}}
              </span>
            </div>
            <div>
              剩余天数：<span>
                {{data.termDays + data.giveDayQty}}天
              </span>
            </div>
            <div v-if="data.cardType === 2">
              剩余次数：<span>
                {{data.availableTicketQty + data.giveTicketQty}}次
              </span>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              截止时间：<span>
                {{data.expireTime}}
              </span>
            </div>
            <div>
              时段限制：<span v-for="(item, index) in data.periodDefList" :key="index">
                <span v-for="(el, ind) in item.day" :key="ind">
                  星期{{changeDay(el)}}
                </span>
                 {{item.start}}~{{item.end}}<br/>
                </span>
            </div>
          </el-col>
        </el-row>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="innerVisible = true">核验</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">关闭</el-button>
      </template>
      <el-dialog
        title="提示"
        :visible.sync="innerVisible"
        width="20%"
        top="25vh"
        append-to-body>
        <span class="innerDialog">{{data.cardType === 1 || data.cardType === 3 ? '是否确定核销？' : '核销将扣除次数，是否确定核销'}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="check-cancel" @click="innerVisible = false" size="small">取 消</el-button>
          <el-button class="check-sure" @click="sureEvent" size="small">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: {},
      cardNo: '',
      innerVisible: false,
    }
  },
  computed: {
    ...mapState([
      "authority",
      "Language",
      "buttonAuth",
      "USERNAME",
      "venueId",
      "venueList",
      "typeList",
      "merchantAccountDetailInfoDTO",
      "ROLES",
    ]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.data = {}
      console.log('我初始化了')
    },
    // 转换日期
    changeDay(date) {
      let day = ''
      if (date === 1) {
        day = '一'
      } else if (date === 2) {
        day = '二'
      } else if (date === 3) {
        day = '三'
      } else if (date === 4) {
        day = '四'
      } else if (date === 5) {
        day = '五'
      } else if (date === 6) {
        day = '六'
      } else if (date === 7) {
        day = '日'
      }
      return day
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      let name = ''
      this.venueList.forEach((item) => {
        if (item.id === this.venueId) {
          name = item.venueName
        }
      }) // apis.timingCardCheckIn // post
      this.$http.post(apis.timingCardCheckIn, {
        venueName: name,
        cardNo: this.cardNo,
      }).then((res) => {
        if (res.data.code !== 0) {
          // this.$message.success(res.data.msg)
          this.$emit('openError', true, res.data.msg);
        }
      });
      console.log(`我执行了点确定的业务需求`)
      this.innerVisible = false;
      // 调用关闭方法，不要再写一次
    },
    getDataQuery(data) {
      this.data = {}
      this.cardNo = data
      console.log(data, 'data')
      console.log(this.cardNo)
      this.$http.get(`${apis.getAllInfoByMemberCardNum}?memberCardNo=${data}`).then((res) => {
        if (res.data.code === 0) {
          this.data = res.data.data
          console.log(res)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
// /deep/.el-dialog__body{
//   text-align: center;
// }
.memberdialog {
  .line {
    border-bottom: 1px solid #D8D8D8;
    .img {
      width: 124px;
      height: 138px;
      border-radius: 2px;
    }
    .imgbox {
      width: 124px;
      height: 138px;
      background: #D8D8D8;
    }
    div {
      font-size: 14px;
      font-family: SourceHanSansSC-Normal, SourceHanSansSC;
      font-weight: 400;
      color: #8B8885;
      margin-bottom: 12px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #242D41;
      }
      .red {
        color: red;
      }
    }
  }
  .second {
    padding-top: 13px;
    div {
      font-size: 14px;
      font-family: SourceHanSansSC-Normal, SourceHanSansSC;
      font-weight: 400;
      color: #8B8885;
      margin-bottom: 12px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #242D41;
      }
    }
  }
}
.innerDialog{
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
.check-sure{
  height:32px;
  width:100px;
  line-height: 8px;
  background:rgba(62,103,174,1);
  border-radius:4px;
  color: #ffffff;
  font-size:14px;
  cursor: pointer;
}
.check-sure:hover{
  background:rgba(62,103,174,1);
  color: white;
}
.check-cancel{
  width:100px;
  height:32px;
  line-height: 8px;
  background:rgba(234,236,241,1);
  border-radius:4px;
  color:rgba(62,103,174,1);
  font-size:14px;
  cursor: pointer;
}
.check-cancel:hover{
  background:rgba(234,236,241,1);
  color:rgba(62,103,174,1);
}
</style>
