/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */

const apis = {
  login: '/auth/login',
  info: '/system/user/info',
  systemName: '/system/baseInfo/config',
  downloadUrl: '/system/common/download',
  downloadUrlOperation: '/operation/common/download',
  loginOut: '/auth/logout',
  userList: '/system/user/list',
  user_update: '/system/user/update',
  user_save: '/system/user/save',
  user_remove: '/system/user/remove',
  role: '/system/role',
  roleList: '/system/role/list',
  roleStatus: '/system/role/status',
  permission_user: '/system/menu/user',
  permission_all: '/system/menu/all',
  permission_list: '/system/menu/list',
  permission_role: '/system/menu/role/',
  permission_update: '/system/menu/update',
  permission_save: '/system/menu/save',
  permission_remove: '/system/menu/remove/',
  venueRoleMap_venueList: '/operation/venueRoleMap/getVenueLits', // 场馆下拉框
  venuevManagement_list: '/operation/venuevManagement/list', // 场馆下拉框
  getVenueListByEntityId: '/operation/venuevManagement/getVenueListByEntityId', // 根据主体id查询场馆下拉列表
  venueRoleMap_list: '/operation/venueRoleMap/list', // 查询角色列表
  venueRoleMap_selectVenueRoleMapById: '/operation/venueRoleMap/selectVenueRoleMapById', // 根据ID查询角色回显场馆
  venueRoleMap_updateVenueRoleMapById: '/operation/venueRoleMap/updateVenueRoleMapById', // 修改角色与场馆
  dept_role: '/system/dept/role/',
  dept_list: '/system/dept/list',
  dept_list_enable: '/system/dept/list/enable',
  dept_update: '/system/dept/update',
  dept_save: '/system/dept/save',
  dept_remove: '/system/dept/remove/',
  role_List: '/system/role/list',
  role_update: '/system/role/update',
  role_save: '/system/role/save',
  role_remove: '/system/role/remove',
  role_all: '/system/role/all',
  selectAllRole: '/system/role/selectAllRole',
  // selectAllVenue: '/system/role/selectAllVenue',
  role_authDataScope: '/system/role/authDataScope',
  dictType_list: '/system/dict/type/list',
  dictType_update: '/system/dict/type/update',
  dictType_save: '/system/dict/type/save',
  dictType_remove: '/system/dict/type/remove',
  dictData_list: '/system/dict/data/list',
  dictData_type: '/system/dict/data/type',
  dictData_update: '/system/dict/data/update',
  dictData_save: '/system/dict/data/save',
  dictData_remove: '/system/dict/data/remove',
  dist_List: '/system/districts/list',
  dist_update: '/system/districts/update',
  dist_save: '/system/districts/save',
  dist_remove: '/system/districts/remove',
  logininfor_List: '/system/logininfor/list',
  logininfor_clean: '/system/logininfor/clean',
  logininfor_remove: '/system/logininfor/remove',
  operLog_List: '/system/operLog/list',
  operLog_export: '/system/operLog/export',
  operLog_clean: '/system/operLog/clean',
  operLog_remove: '/system/operLog/remove',
  operLog_modulesList: '/system/operLog/findTitle',
  config_List: '/system/config/list',
  config_update: '/system/config/update',
  config_save: '/system/config/save',
  config_remove: '/system/config/remove',
  oss_List: '/system/oss/list',
  oss_update: '/system/oss/update',
  oss_save: '/system/oss/save',
  oss_remove: '/system/oss/remove',
  resetPassword: '/system/user/resetPwd',
  userStatus: '/system/user/status',
  userRemove: '/system/user/remove',
  SysDictList: '/system/dict/data/getSysDictList', // 公共下拉
  deviceList: '/dss-mgt/device/list', // 设备列表
  deviceRemove: '/dss-mgt/device/remove', // 设备删除
  areaManger: '/dss-mgt/manage/list', // 区域管理
  areaAdd: '/dss-mgt/manage/save', // 新增区域
  areaRemove: '/dss-mgt/manage/remove', // 删除区域
  areaUpdate: '/dss-mgt/manage/update', // 更新区域
  areaQueryData: '/dss-mgt/manage/getInfoById', // 获取区域回显数据
  // areaAllList: '/dss-mgt/appConfig/getRegionalList', // 区域下拉
  waringList: '/dss-mgt/configuration/selectAlarmTriggerConfigurationList', // 告警触发设置列表
  waringQueryData: '/dss-mgt/configuration/selectAlarmTriggerConfigurationById', // 告警回显
  waringUpdate: '/dss-mgt/configuration/update', // 告警保存
  waringSave: '/dss-mgt/configuration/addMoreDevices', // 告警新增
  deviceAllList: '/dss-mgt/configuration/selectIntelligentDeviceList', // 获取所有设备的下拉列表
  deviceQuery: '/dss-mgt/device/getInfoById', // 设备回显
  deviceUpdate: '/dss-mgt/device/update', // 设备修改保存
  alarmRecordList: '/dss-daq/alarmrecord/alarmRecordList', // 设备告警记录-列表
  deviceMonitorList: '/dss-daq/device_monitoring/selectList', // 设备监控LIST
  deviceMonitorList1: '/dss-daq/device_monitoring/selectSmokeDetectorRecordById', // 查询烟雾传感器历史记录
  deviceMonitorList2: '/dss-daq/device_monitoring/selectAmeterRecordById', // 查询电表传感器历史记录
  chart1: '/dss-daq/chart_statistics/selectElectricCurrent', // 获取电流图标
  chart2: '/dss-daq/chart_statistics/selectPositiveActivePower', // 查询正向有电功电度
  chart3: '/dss-daq/chart_statistics/selectVoltage', // 查询电压
  chart4: '/dss-daq/chart_statistics/selectSmokeDetector', // 查询烟雾报警器
  chartApi1: '/dss-daq/chart_statistics_ccs/selectElectricCurrent', //改为本地接口 获取电流图标
  chartApi2: '/dss-daq/chart_statistics_ccs/selectPositiveActivePower', //改为本地接口 查询正向有电功电度
  chartApi3: '/dss-daq/chart_statistics_ccs/selectVoltage', //改为本地接口 查询电压
  chartApi4: '/dss-daq/chart_statistics_ccs/selectSmokeDetector', //改为本地接口 查询烟雾报警器
  deviceSave: '/dss-mgt/device/save', // 智能设备新增
  waringRemove: '/dss-mgt/configuration/removeAlarmTriggerConfiguration', // 告警设置删除
  selectPower: '/dss-daq/chart_statistics/selectPower', // 查询正负总功率
  selectPowerApi: '/dss-daq/chart_statistics_ccs/selectPower', //改为本地接口 查询正负总功率
  reservateOrder_list: '/operation/reservateOrder/list', // 查看排班列表
  reservateOrder_save: '/operation/reservateOrder/save', // 保存排班
  tickeOrder_list: '/operation/tickeOrder/list', // 查询票务
  tickeOrder_save: '/operation/tickeOrder/save', // 保存票务
  getReservateOrderById: '/operation/reservateOrder/getReservateOrderById', // 查询预约支付信息
  tic_ReservateOrderById: '/operation/tickeOrder/getTickeOrderById', // 查询票务支付信息
  checkInByTickNo: '/operation/checkin/checkInByTickNo', // 验票
  queryOrderManagementList: '/operation/ordermanagement/queryOrderManagementList', // 查询订单管理列表
  queryOrderManagementListWithSettlementInfo: '/operation/ordermanagement/queryOrderManagementListWithSettlementInfo', // 结算管理-订单管理列表
  orderDetailsWithSettlementNewInfo: '/operation/ordermanagement/orderDetailsWithSettlementInfo', // 结算管理详情
  updateRemark: '/operation/ordermanagement/updateRemark', // 订单备注
  orderDetails: '/operation/ordermanagement/orderDetails', // 查询订单详情
  closeOrder: '/operation/ordermanagement/closeOrder', // 关闭订单
  checkInByTickNoInfo: '/operation/checkinrecord/checkInByTickNo', // 验票记录查询
  SaveReservate: '/operation/goodsAndServices/SaveReservate', // 保存预约信息
  selectVenueTicketSet: '/operation/goodsAndServices/selectVenueTicketSet', // 查询票务信息
  insertVenueTicketSet: '/operation/goodsAndServices/insertVenueTicketSet', // 新增票务信息
  deleteVenueTicketSet: '/operation/goodsAndServices/deleteVenueTicketSet', // 新增票务信息
  modifyStrtus: '/operation/goodsAndServices/modifyStrtus', // 修改票务状态
  updateVenueTicketSet: '/operation/goodsAndServices/updateVenueTicketSet', // 修改票务信息
  selectVenueTicketSetById: '/operation/goodsAndServices/selectVenueTicketSetById', // 修改票务信息
  queryCustomerList: '/operation/customermanager/queryCustomerList', // 查询客户列表
  addCustomer: '/operation/customermanager/addCustomer', // 新增客户
  updateCustomer: '/operation/customermanager/updateCustomer', // 修改客户
  selectAdvancedSetting: '/operation/goodsAndServices/selectAdvancedSetting', // 查询高级设置
  saveAdvancedSetting: '/operation/goodsAndServices/saveAdvancedSetting', // 保存高级设置
  venuevManagementList: '/operation/venuevManagement/list', // 场馆管理-查询
  customermanagerExport: '/operation/customermanager/export', // 客户管理-导出
  venuevManagementSelectVenuevManagementById: '/operation/venuevManagement/selectVenuevManagementById', // 场馆回显
  venuevManagementInsertVenuevManagement: '/operation/venuevManagement/insertVenuevManagement', // 场馆新增
  venuevManagementUpdateVenuevManagement: '/operation/venuevManagement/updateVenuevManagement', // 场馆 修改
  venuevManagementDeleteVenuevManagement: '/operation/venuevManagement/deleteVenuevManagementById', // 场馆 删除
  venuevManagementSelectVenuevSpaceInfo: '/operation/venuevManagement/selectVenueSpaceInfoById', // 场地 查询
  venuevManagementInsertVenuevSpaceInfo: '/operation/venuevManagement/insertVenueSpaceInfo', // 场地 新增
  queryCustomerDetailInfo: '/operation/customermanager/queryCustomerDetailInfo', // 查询客户详情-头部数据
  queryCustomerOrderList: '/operation/customermanager/queryCustomerOrderList', //查询客户详情-表格
  selectReservate: '/operation/goodsAndServices/selectReservate', //查询预约信息
  delVenueSpaceInfoById: '/operation/venuevManagement/delVenueSpaceInfoById', //场地维护删除
  modelsList: '/act/models/list', //模型管理列表
  modDelsRemove: '/act/models/remove', //模型管理删除
  modelsDeploy: '/act/models/deploy', //模型管理发布
  profAllList: '/act/prof/allLatest', //申请列表集合
  profList: '/act/prof/list', //模型管理列表
  profRemove: '/act/prof/remove', //删除
  process: '/act/process', //资源名称下载
  processDeployByFile: '/act/process/deployByFile', //上传部署流程文件
  node: 'act/node',
  nodeUpdate: 'act/node/update',
  processInss: '/act/process/ins',
  processIns: '/act/process/ins/runs', //运行中的流程列表
  processInsRemove: '/act/process/ins/remove',
  processInsFinished: '/act/process/ins//finished',
  processInsRemoveHis: '/act/process/ins/remove/his',
  business: 'act/business/list/my', //我的申请列表
  businessRemove: 'act/business/remove', //申请删除
  taskList: 'act/task/ing', //代办列表
  taskListDone: 'act/task/done', //已办列表
  taskBatch: 'act/task/audit/batch', //代办列表
  leave: 'act/leave/biz', //获取代办中列表单条数据
  taskAudit: 'act/task/audit', //审议页面同意和驳回
  taskFlow: 'act/task/flow', //任务流转历史
  taskRemove: 'act/task/remove', //我的己办--删除
  purchaseSave: 'act/purchase/save', //我的申请 保存
  leaveSave: 'act/leave/save', //我的申请 请假 保存
  newModel: 'act/models/newModel', //模型新增
  editModel: 'act/models/editModel', //模型编辑
  courseManage: '/operation/courseManage', //课程管理=课程新增、修改、删除、
  coachInfo: '/operation/coachInfo', //教练管理=课程新增、修改、删除、
  coachInfoList: '/operation/coachInfo/list', //教练管理 列表
  matchInfo: '/match/matchInfo', //赛事管理=课程新增、修改、删除、
  matchInfoList: '/match/matchInfo/list', //赛事管理 列表
  courseManageList: '/operation/courseManage/list', //课程管理=课程列表、
  updatePublishStatus: '/match/matchInfo/updatePublishStatus', //赛事管理 修改发布状态
  courseUpdatePublishStatus: '/operation/courseManage/updatePublishStatus', //赛事管理 修改发布状态
  courseApplyInfo: '/operation/courseApplyInfo/statistic', //课程 报名统计
  informationList: '/operation/information/list', //资讯管理 列表
  information: '/operation/information', //资讯管理 新增 修改 删除
  informationUpdatePublishStatus: '/operation/information/updatePublishStatus', //资讯管理 修改发布状态
  informationUpdateTop: '/operation/information/updateTop', //资讯管理 修改置顶状态
  matchInfoUpdateTop: '/match/matchInfo/updateTop', //资讯管理 修改置顶状态
  courseManageUpdateTop: '/operation/courseManage/updateTop', //资讯管理 修改置顶状态
  courseManagePreview: '/operation/courseManage/preview', //课程管理 预览
  courseApplyInfoList: '/operation/courseApplyInfo/list', //报名课程列表
  matchApplyInfoList: '/match/matchApplyInfo/list', //赛事 报名列表
  matchApplyInfoStatistic: '/match/matchApplyInfo/statistic', //赛事报名 统计
  ordermanagementRefund: '/operation/ordermanagement/refund', //订单-退款
  courseExport: '/operation/courseApplyInfo/export', //课程-报名导出
  matchExport: '/match/matchApplyInfo/export', //赛事-报名导出
  contentCategorygetIdAndName: '/operation/convenienceManage/getIdAndName', // 便民服务-获取tabs内容
  convenienceManagelist: '/operation/convenienceManage/list', // 便民服务-查询获取表格内容
  convenienceManage: '/operation/convenienceManage', // 便民服务-增删改查
  convenienceManageUpdateStatus: '/operation/convenienceManage/updateStatus', // 便民服务-发布下架
  convenienceManageUpdateTop: '/operation/convenienceManage/updateTop', // 便民服务-置顶非置顶
  omscenicspotList: '/operation/omscenicspot/list', // 必游景点管理-查询列表
  omscenicspotSave: '/operation/omscenicspot/save', // 必游景点管理-新增
  omscenicspotUpdate: '/operation/omscenicspot/update', // 必游景点管理-修改
  omscenicspotGet: '/operation/omscenicspot/get', // 必游景点管理-修改回显
  omscenicspotRemove: '/operation/omscenicspot/remove', // 必游景点管理-删除
  omscenicspotUpdatePublishStatus: '/operation/omscenicspot/updatePublishStatus', // 必游景点管理-发布状态
  omscenicspotUpdateTop: '/operation/omscenicspot/updateTop', // 必游景点管理-置顶
  getVrManagelist: '/operation/omVrManage/queryVrList', // vr管理-获取列表
  delVr: '/operation/omVrManage/delVr', // vr管理-删除
  topVr: '/operation/omVrManage/topVr', // vr管理-置顶
  updateVrPublishStatus: '/operation/omVrManage/updatePublishStatus', // vr管理-上下架
  updataVr: '/operation/omVrManage/updateVr', // vr管理-修改
  addVr: '/operation/omVrManage/addVr', // vr管理-新增
  queryVr: '/operation/omVrManage/queryVr', // vr管理-查询
  getContentCategoryList: '/operation/contentCategory/list', // 内容分类管理-列表
  deleteContentCategory: '/operation/contentCategory', // 内容分类管理-删除
  updateContentCategoryTop: '/operation/contentCategory/updateTop', // 内容分类管理-置顶
  updateContentCategory: '/operation/contentCategory', // 内容分类管理-修改
  matchResultEdit: '/match/matchResult', //赛事成绩-修改
  matchResultList: '/match/matchResult/list', //赛事成绩-列表
  matchNameList: '/operation/venuevManagement', //场馆-赛事列表List
  getTicketList: '/operation/tickeOrder/listById', //获取票务信息列表
  check5k: '/match/matchApplyInfo/check', //5k报名审核
  matchApplyInfo: '/match/matchApplyInfo', //5k报名明细
  invatationList: '/match/matchInvite/list', //赛事邀请列表
  generatorCode: '/match/matchInvite/insert', //生成邀请码
  deleteCode: '/match/matchInvite', //删除邀请码
  getCompetition: '/match/matchInvite/', //获取赛事
  getTeamList: '/match/matchTeam/list', //5k团队列表
  deleteMatch: '/match/matchApplyInfo', //删除5k团队列表
  importMatchApply: '/kettle/excel/importMatchApply', // 报名情况导入
  exportFairPlay: '/kettle/excel/exportFairPlay', // 导出计时计分设备信息
  exportMatchApply: '/kettle/excel/exportMatchApply', // 导出报名信息
  excelMakeResult: '/kettle/excel/makeResult', // 生成成绩排名
  matchThemeList: '/match/matchTheme/list', // 获取赛事主题
  matchThemeGetApplyInfo: '/match/matchTheme/getApplyInfo', //获取报名字段（5K团队）
  ossSaveConfig: '/system/oss/saveConfig', //保存云存储配置信息
  ImageUpload: '/system/oss/upload', //上传
  staticImgUpload: '/fileApi/Assets/File/UploadNew', //静态资源上传
  ossList: '/system/oss/queryList', //列表
  ossRemove: '/system/oss/remove', //删除
  ossConfig: '/system/oss/config', //云存储配置信息
  ossUpdate: '/system/oss/update', //修改文件名
  contentCategoryList: '/fitness-instruction/contentCategory/list', // 健身指导-内容分类列表查询
  contentCategory: '/fitness-instruction/contentCategory', // 健身指导-添加内容分类
  fitnessinstructionlist: '/fitness-instruction/fitnessinstruction/list', // 健身指导-查询列表
  contentCategoryGetIdAndName: '/fitness-instruction/contentCategory/getIdAndName', // 健身指导-获取内容主键id和分类名
  fitnessinstructionAdd: '/fitness-instruction/fitnessinstruction/add', // 健身指导-新增
  fitnessinstruction: '/fitness-instruction/fitnessinstruction', // 健身指导修改、删除
  fitnessinstructionUpdateStatus: '/fitness-instruction/fitnessinstruction/updateStatus', // 健身指导-上架下架
  fitnessinstructionUpdateTop: '/fitness-instruction/fitnessinstruction/updateTop', // 健身指导-修改置顶状态
  getCouponList: '/operation/coupon/list', //获取优惠券列表
  getCouponDetail: '/operation/coupon/get', //优惠券明细
  saveCoupon: '/operation/coupon/save', //新增优惠券
  updateCoupon: '/operation/coupon/update', //修改优惠券
  deleteCoupon: '/operation/coupon/delete', //删除优惠券
  getCouponInfoList: '/operation/coupon/getCouponInfoList', //获取优惠券详情
  getCouponStatus: '/operation/coupon/getQRUrl', //判断二维码是否过期
  getVenueList: '/operation/coupon/getVenueList', //获取适用场馆下拉列表
  getSceneList: '/operation/coupon/getMatchList', //获取适用场景下拉列表
  fitnessDeviceManageList: '/fitness-device/fitnessDeviceManage/list', //【健身器材】健身器材列表
  fitnessDeviceManageGetIdAndName: '/fitness-device/fitnessDeviceManage/getIdAndName', //【健身器材】获取内容主键id和分类名
  fitnessDeviceManage: '/fitness-device/fitnessDeviceManage', //【健身器材】健身器材添加，修改，删除
  fitnessDeviceManageUpdateStatus: '/fitness-device/fitnessDeviceManage/updateStatus', //【健身器材】修改状态（上架/下架）
  fitnessDeviceManageUpdateTop: '/fitness-device/fitnessDeviceManage/updateTop', //【健身器材】修改置顶状态
  maintenanceReportList: '/fitness-device/maintenanceReport/list', //【维修上报】 器材维修列表查询
  maintenanceReport: '/fitness-device/maintenanceReport', //【维修上报】 器材维修列表详情
  brokenTypeList: '/fitness-device/brokenType/list', //【维修上报】 损耗类型列表
  brokenType: '/fitness-device/brokenType', //【维修上报】 增删改损耗类型
  fitnessContentCategoryList: '/fitness-device/fitnessContentCategory/list', // 【健身器材分类】内容分类列表查询
  fitnessContentCategory: '/fitness-device/fitnessContentCategory', //【健身器材分类】健身器材添加，修改，删除
  fitnessContentCategoryUpdateTop: '/fitness-device/fitnessContentCategory/updateTop', //【健身器材分类】修改置顶状态
  fitnessDeviceType: "/fitness-device/fitnessDeviceType", //【健身器材类型】 健身器材类型删除
  fitnessDeviceTypeList: "/fitness-device/fitnessDeviceType/list", //【健身器材类型】查询健身器材类型列表
  fitnessDeviceTypeSave: "/fitness-device/fitnessDeviceType/save", //【健身器材类型】新增健身器材类型
  fitnessDeviceTypeGet: "/fitness-device/fitnessDeviceType/get", //【健身器材类型】查询健身器材类型
  fitnessDeviceTypeUpdate: "/fitness-device/fitnessDeviceType/update", //【健身器材类型】修改健身器材类型
  getMainDirectionList: '/operation/venuevManagement/mainDirectionList', // 场馆管理-基础场馆-获取主营方向列表
  venueInfoList: '/operation/venuevManagement/venueInfoList', // 场馆管理-基础场馆-查询列表
  insertVenueInfo: '/operation/venuevManagement/insertVenueInfo', // 场馆管理-基础场馆-新增
  venueInfoDetail: '/operation/venuevManagement/venueInfoDetail', // 场馆管理-基础场馆-修改回显
  updateVenueInfo: '/operation/venuevManagement/updateVenueInfo', // 场馆管理-基础场馆-修改保存
  removeVenueInfo: '/operation/venuevManagement/removeVenueInfo', // 场馆管理-基础场馆-删除
  importVenueInfo: '/operation/venuevManagement/importVenueInfo', // 场馆管理-基础场馆-导入
  physiqueList: '/physique-monitoring/physiqueMonitoring/list', // 体质监测列表
  physiqueDetail: '/physique-monitoring/physiqueMonitoring', //体质监测详情
  physiqueAdd: '/physique-monitoring/physiqueMonitoring', // 新增体质监测
  physiqueEdit: '/physique-monitoring/physiqueMonitoring', // 修改体质监测
  physiqueDel: '/physique-monitoring/physiqueMonitoring', // 删除体质监测
  physiquePublish: '/physique-monitoring/physiqueMonitoring/updateStatus', // 发布体质监测
  physiqueUpdateTop: '/physique-monitoring/physiqueMonitoring/updateTop', // 置顶体质监测
  physiqueUpdateStatus: '/physique-monitoring/physiqueMonitoring/updateStatus', // 发布、上下架体质监测
  updateVenueReservationSupport: '/operation/venuevManagement/updateVenueReservationSupport', // 场馆管理-基础场馆-导入-商家状态切换
  downloadVenueImportTemplate: '/operation/venuevManagement/download', // 场馆管理-基础场馆-下载导入模板
  getWebsiteInformationList: '/arc-website/news/list', // 获取官网资讯-列表
  websiteInformation: '/arc-website/news', // 获取官网资讯-新增-修改-查询-删除
  getWebsiteInformationDetail: '/arc-website/news/websiteDetail', // 获取官网资讯-查询
  updateWebsiteInformationStatus: '/arc-website/news/updateStatus', // 获取官网资讯-查询
  createWebsiteWbMenu: '/arc-website/wbMenu', // 新增官网菜单
  menuWbMenuList: '/arc-website/wbMenu/list', // 查询官网菜单列表
  queryMemberList: '/operation/memberPersonInfo/pageByKeyword', // 查询会员管理列表 //http://192.168.0.157:8006
  addMemberInfo: '/operation/memberPersonInfo/add', // 会员管理-新增会员
  updateMember: '/operation/memberPersonInfo/update', // 会员管理-修改会员
  sysMemberList: '/system/sysMember/list', //查询平台会员列表
  sysMemberStatus: '/system/sysMember/changeStatus', //改变账号状态
  sysMemberUpdate: '/system/sysMember/update', //修改姓名和手机号
  getUserFaceInfo: '/system/sysMember/getUserFaceInfo', //获取人脸识别信息
  videoClassificationList: '/operation/courseType/selectVideoCourseType', // 线上视频分类管理列表
  addCourseType: '/operation/courseType/addCourseType', // 新增视频分类
  updateCourseType: '/operation/courseType/updateCourseType', // 修改视频分类
  deleteCourseType: '/operation/courseType/deleteCourseType', // 删除视频分类
  selectAllType: '/operation/courseType/selectAllType', // 视频课程tab栏分类
  selectVideoCoures: '/operation/course/selectVideoCoures', // 视频课程列表
  addVideoCoures: '/operation/course/addVideoCoures', // 视频新增
  selectType: '/operation/courseType/selectType', // 新增视频-视频分类
  getIndexIotDevice: '/iot/iotdevice/getIndexIotDevice',
  getIotDeviceListByType: '/iot/iotdevice/getIotDeviceListByType', //闸机-列表数据
  delIotDevice: '/iot/iotdevice/delIotDevice', // 删除智能设备
  addIotDevice: '/iot/iotdevice/addIotDevice', // 新增智能设备
  updateIotDevice: '/iot/iotdevice/updateIotDevice', // 修改智能设备信息
  getIotDeviceDetail: '/iot/iotdevice/getIotDeviceListByPid', // 智能设备列表详情
  getLiveCameraList: '/iot/liveVideo/selectLiveCamera',
  getLivePreview: '/iot/liveVideo/preview',
  getLivePlayback: '/iot/liveVideo/playback',
  getliveBroadcastList: '/iot/liveVideo/selectLiveVideoRecordList',
  getliveClipstList: '/iot/liveVideoClip/selectLiveVideoClipRecordList',
  getClipsListByLiveId: '/iot/liveVideo/selectClipListByLiveVideoId', //根据视频直播id查找剪辑记录
  delectVideoCourse: '/operation/course/delectVideoCourse', // 删除视频课程
  updateVideoCourse: '/operation/course/updateVideoCourse', // 修改课程视频
  upOrDownCourse: '/operation/course/upOrDownCourse', // 视频课程上下架
  topCourse: '/operation/course/topCourse', // 课程视频是否置顶
  selectOneCourse: '/operation/course/selectOneCourse', // 根据id查询课程和类型还有视频地址
  getIotDeviceWarnList: '/iot/iotdevice/getIotDeviceWarnList', // 设备告警列表查询
  getIotTemperatureWarn: '/iot/iotdevice/getIotTemperatureWarn', // 人体高温告警记录
  getSportsProjectCode: '/operation/venuevManagement/getVenueSpaceInfoBySportsProjectCode', // 根据运动项目查询场地列表
  getVenue: '/iot/bodyMonitor/getVenue', // 场馆列表
  getBodyMonitorList: '/iot/bodyMonitor/getBodyMonitorList', //体质监测仪列表
  addBodyMonitor: '/iot/bodyMonitor/addBodyMonitor', // 新增体质监测仪
  updateBodyMonitor: '/iot/bodyMonitor/updateBodyMonitor', //修改体质监测仪信息
  delMontiorDevice: '/iot/bodyMonitor/delBodyMonitor', //删除设备
  getSportsProject: '/iot/bodyMonitor/getSportsProject', // 运动项目列表
  getSportsNewProject: '/operation/venuevManagement/getSportsProjectListById', // 通过场馆id查询运动项目列表
  getSpaceInfo: '/iot/bodyMonitor/getSpaceInfo', // 场地列表
  venueVideo: '/operation/venueVideo/list', // 查询所有场馆视频
  changePublishStatus: '/operation/venueVideo/changePublishStatus', // 改变场馆视频发布状态
  changeTop: '/operation/venueVideo/changeTop', // 改变是否置顶
  deleteVenueVideo: '/operation/venueVideo/deleteVenueVideo', // 删除场馆视频
  insertVenueVideo: '/operation/venueVideo/insertVenueVideo', // 新增视频
  updateVenueVideo: '/operation/venueVideo/updateVenueVideo', // 修改场馆视频
  getSportsProjectListById: '/operation/reservateOrder/getSportsProjectListById', //根据场馆id查询运动项目列表
  getPaymentMethods: '/operation/orderReport/findPayCategory', // 获取支付方式列表
  getPaymentTypes: '/operation/ordermanagement/findPayType', //获取支付类型列表
  // getVenueSpace: 'operation/venuevManagement/getVenueSpaceInfoBySportsProjectCode', // huo
  homeinfoList: '/operation/homeinfo/allInfo', // 根据类型查询首页列表
  queryOccupancyRate: '/operation/homeinfo/queryOccupancyRate', // 今日占场率
  getCardListByTel: '/operation/reservateOrder/selectMemberHoldPrepaidCard', // 查询储值卡
  switchControl: '/iot/v1/iot/switchControl', //智能开关控制数据
  getSportsProjectList: '/operation/venuevManagement/getSportsProjectListById', // 场馆管理/运营 运动项目
  getpassengerFlowData: '/iot/venueDeviceData/passengerFlowData', //场馆首页 在馆人数
  appCancelOrder: '/operation/mp/moordermanagemnt/appCancelOrder', // 取消预约
  getPrintInfo: '/operation/reservateOrder/getPrintInfo', // 获取小票详情
  gateDoorOpen: '/iot/v1/iot/gateDoor/open', //闸机开门
  getAdvenceTime: '/operation/pcReservateRule/getAdvenceTime', //获取入场提前时间
  updateAdvenceTime: '/operation/pcReservateRule/updateAdvenceTime', //修改入场提前时间
  getSharinglimit: '/operation/pcReservateRule/getSharinglimit', //获取分享限制
  updateSharinglimit: '/operation/pcReservateRule/updateSharinglimit', //修改分享限制
  listIncome: '/operation/orderIncome/listIncome', // 订单收入统计
  findAllMerchant: '/platform-manage/merchantAccount/findAllMerchant', // 商户下拉
  findEntityByMerchantId: '/platform-manage/merchantAccount/findEntityByMerchantId', // 主体下拉
  queryOrderManagementNewList: '/platform-manage/merchantSettlement/ordermanagement/queryOrderManagementList', // 订单管理--订单列表--资金明细
  orderDetailsWithSettlementInfo: '/platform-manage/merchantSettlement/ordermanagement/orderDetailsWithSettlementInfo', // 订单管理--资金明细--详情
  temperatureConfig: '/iot/iotdevice/temperatureConfig', // 闸机开启关闭测温
  findVideoCourseByCourseId: '/operation/courseManage/findVideoCourseByCourseId', // 视频课程详情列表
  deleteVideoCourseRelevance: '/operation/courseManage/deleteVideoCourseRelevance', // 视频课程详情列表移除
  updateVideoCourseSort: '/operation/courseManage/updateVideoCourseSort', // 视频课程详情列表修改排序
  addOrUpdateVideoCourseRelevanceCourse: '/operation/courseManage/addOrUpdateVideoCourseRelevanceCourse', // 新增培训课程关联视频课程
  getDataPermissionOutput: '/system/role/getDataPermissionOutput', // 角色管理数据权限下拉列表
  getDataPermissionOutputWhenUpdate: '/system/role/getDataPermissionOutputWhenUpdate',
  reservationRecord: '/operation/reservationRecord/list', // 查询预定记录
  getInvitedDetail: '/operation/reservationRecord/getInvitedDetail', // 邀请详情
  getBeInvitedDetail: '/operation/reservationRecord/getBeInvitedDetail', // 受邀详情
  getSharingResultPrintInfo: '/operation/reservationRecord/getSharingResultPrintInfo', // 预订记录-小票打印
  updateSharingResultRemark: '/operation/reservationRecord/updateSharingResultRemark', // 预订记录修改备注
  updateOrderDetailRemark: '/operation/ordermanagement/updateOrderDetailRemark', // 首页扫码-订单备注
  getMatchType: '/match/matchGroupType/list', //赛事组别类型列表
  venueEventInfoList: '/entertainment/venueEventInfo/list', //获取活动列表
  venueEventInfo: '/entertainment/venueEventInfo', //新增、删除、查看、修改活动
  updateEventInfoStatus: '/entertainment/venueEventInfo/updatePublishStatus', //发布活动
  updateEventInfoTop: '/entertainment/venueEventInfo/updateTop', //发布活动
  getCodeList: '/entertainment/venueEventTheme/list', //获取活动主题
  getJoinInfo: '/entertainment/venueEventTheme/getJoinInfo', //获取活动主题
  orderDetailList: '/operation/ordermanagement/orderDetailList', //展开订单
  findMerchantAccountDetailInfo: '/platform-manage/merchantAccount/findMerchantAccountDetailInfo', //商户管理-账号管理-商户详情
  merchantWithdrawal: '/platform-manage/merchantWithdrawal/list', // 结算管理-提现管理列表
  getDetail: '/platform-manage/merchantWithdrawal/getDetail', // 结算管理-提现管理-详情
  withdrawal: '/platform-manage/merchantWithdrawal/withdrawal', // 首页-提现
  findMerchantAccountInfoList: '/platform-manage/merchantAccount/findMerchantAccountInfoList', //商户管理-账号管理-列表查询
  getVenueAccountList: '/operation/venuevManagement/getVenueAccountList', //查询场馆账户列表
  addVenueAdmin: '/operation/venuevManagement/addVenueAdmin', //新增场馆
  updateVenueAdmin: '/operation/venuevManagement/updateVenueAdmin', //修改场馆账户状态，密码
  getVenueListByMerchantId: '/operation/venuevManagement/getVenueListByMerchantId', //获取场馆名称列表
  delMerchant: '/operation/venuevManagement/deleteVenueAdmin', //删除场馆账户
  getMerchantInfo: '/operation/homeinfo/getMerchantInfo', // 商户基本信息查询
  cancelReserve: '/operation/reservateOrder/cancelReserve', // 取消预定
  getUserByVenueAccount: '/operation/venuevManagement/getUserByVenueAccount', // 账号查询密码
  queryMerchantFinancialStatement: '/operation/orderReport/queryMerchantFinancialStatement', // 商户财务报表
  queryFinancialStatementOrders: '/operation/orderReport/queryFinancialStatementOrders', // 财务报表的具体订单列表查询
  orderReportexport: '/operation/orderReport/export', // 财务报表导出
  exportFinancialStatementOrders: '/operation/orderReport/exportFinancialStatementOrders', // 导出财务报表的具体订单列表
  getVenueManageInfoById: '/operation/venuevManagement/getVenueManageInfoById', // 会员卡-获取场馆主体id
  timingCardCheckIn: '/operation/memberCard/index/timingCardCheckIn', // 首页-期限卡入场
  getAllInfoByMemberCardNum: '/operation/memberCard/index/getAllInfoByMemberCardNo', // 通过MemberCardNum会员卡号获取所有信息
  getSimpleCardInfoByTelephone: '/operation/memberCard/index/getSimpleCardInfoByTelephone', // 通过手机号获取简单的卡信息，用于下拉列表

  // 1.1迁移过来
  // 会员卡模块相关
  getProductTagList: '/ssv-member-card/api/productTag/getList', // 获取标签列表
  addProductTag: '/ssv-member-card/api/productTag/add', // 添加标签
  updateProductTag: '/ssv-member-card/api/productTag/update', // 编辑标签
  deleteProductTag: '/ssv-member-card/api/productTag/delete', // 删除标签
  getProductTagById: '/ssv-member-card/api/productTag/getById', // 根据id获取标签

  getproductColumnList: '/ssv-member-card/api/productColumn/getList', // 获取栏目列表
  addproductColumn: '/ssv-member-card/api/productColumn/add', // 添加栏目
  updateproductColumn: '/ssv-member-card/api/productColumn/update', // 编辑栏目
  deleteproductColumn: '/ssv-member-card/api/productColumn/delete', // 删除栏目
  getproductColumnById: '/ssv-member-card/api/productColumn/getById', // 根据id获取栏目

  cardTypeFind: '/ssv-member-card/api/cardType/find', // 会员卡管理-查询列表
  cardTypeSave: '/ssv-member-card/api/cardType/save', // 会员卡管理-新增
  dictGetSports: '/ssv-member-card/api/dict/getSports', // 获取运动项目
  cardTypeGetById: '/ssv-member-card/api/cardType/getById', // 会员卡管理-根据id获取会员卡类详情
  cardTypedeleteById: '/ssv-member-card/api/cardType/deleteById', // 会员卡管理-删除
  cardTypeupdateStatus: '/ssv-member-card/api/cardType/updateStatus', // 会员卡管理-修改状态
  cardTypeupdate: '/ssv-member-card/api/cardType/update', // 会员卡管理-修改保存

  //会员卡产品列表,详情
  getProductList: '/ssv-member-card/api/memberCard/getProductList', // 会员卡产品列表
  getProductById: '/ssv-member-card/api/memberCard/getProductById', // 会员卡产品详情
  buyCard: '/operation/memberCard/buyCard', // 会员卡购卡
  // buyCard: '/buyCard/memberCard/buyCard', // 会员卡购卡
  //会员信息会员卡相关
  getUserInfo: '/system/user/findByUserInfo', // 根据姓名或手机号查询用户
  updateUserInfo: '/system/user/update/info', // 更新会员头像
  updateUserInfo2: '/system/user/wechatUpdateUser', // 更新会员头像
  getUserInfoByUserId: '/system/user/get/', // 根据userId查询用户, 放在url上
  getBuyCardRecord: '/ssv-member-card/api/purchaseRecordInfo/getInfo', // 购卡记录分页查询
  getSignInRecord: '/ssv-member-card/api/writeOffRecord/list', // 会员卡签到核销记录分页查询
  getUserCardList: '/ssv-member-card/api/userCard/findByUser', // 根据会员id获取持卡列表
  activateUserCard: '/ssv-member-card/api/userCard/active', // 会员卡激活
  checkinUserCard: '/operation/userCard/checkIn', // 会员卡签到核销
  getDetailByCardId: '/ssv-member-card/api/userCard/getDetailByCardId', // 根据会员卡id获取会员卡详情
  giveDayUserCard: '/ssv-member-card/api/userCard/giveDay', // 会员卡赠送天数
  giveTicketUserCard: '/ssv-member-card/api/userCard/giveTicket', // 会员卡赠送次数
  rechargeUserCard: '/operation/userCard/recharge', // 储值卡充值
  refundUserCard: '/operation/memberCard/refund', // 会员卡退卡退款
  stopUserCard: '/ssv-member-card/api/userCard/stop', // 会员卡停卡
  transferUserCard: '/operation/userCard/transfer', // 会员卡会员卡转卡
  updateCardNumber: '/ssv-member-card/api/userMemberController/updateCardNumber', // 制卡,补卡,更新会员卡号信息
  getCardFlowList: '/ssv-member-card/api/memberHome/getCardFlowList', // 获取卡操作记录(退卡、转卡、停卡、激活等)
  getCardUseList: '/ssv-member-card/api/memberHome/getCardUseList', // 获取卡使用记录(储值卡，次卡，期限卡对应的使用记录，赠送天数，次数等)
  getOrderByCardId: '/ssv-member-card/api/memberHome/getOrderByCardId', // 获取购卡信息
  deductionMoney: '/ssv-member-card/api/userCard/deductAmount', //储值卡补扣金额

  // 产品销售活动相关接口
  activitygetList: '/ssv-member-card/api/activity/getList', // 查询
  cardTypegetAllCardType: '/ssv-member-card/api/cardType/getAllCardType', // 获取所有基础卡类
  activityadd: '/ssv-member-card/api/activity/add', // 创建活动
  productgetProductVoListById: '/ssv-member-card/api/product/getProductVoListById', // 通过活动ID获取产品列表
  productupdate: '/ssv-member-card/api/product/update', // 设置/编辑产品
  productgetById: '/ssv-member-card/api/product/getById', // 获取产品详情
  productColumngetAll: '/ssv-member-card/api/productColumn/getAll', // 获取所有栏目列表
  productTaggetAll: '/ssv-member-card/api/productTag/getAll', // 获取标签列表
  activitydelete: '/ssv-member-card/api/activity/delete', // 删除活动
  activitypublish: '/ssv-member-card/api/activity/publish', // 发布活动
  activitystop: '/ssv-member-card/api/activity/stop', // 结束活动
  activitygetById: '/ssv-member-card/api/activity/getById', // 获取活动详情
  activityupdate: '/ssv-member-card/api/activity/update', // 编辑活动
  activityperformanceData: '/ssv-member-card/api/activity/performanceData', // 数据效果
  // 迁移到此
  getGateList: '/operation/api/gateManager/list', //获取闸机列表
  addGate: '/operation/api/gateManager/add', //添加闸机
  updateGate: '/operation/api/gateManager/update', //修改闸机
  deleteGate: '/operation/api/gateManager/delete', //删除闸机
  getGateRecord: '/operation/api/gateRecord/list', //获取闸机记录
  getCheckType: '/operation/api/gateRecord/checkType', //获取验证方式
  getDeviceInfo: '/ssv-checkgate/api/deviceInfo/findAll', //获取中控设备
  // 1.0.5
  userCardFindStorageUseRecord: '/operation/userCard/findStorageUseRecord', // 会员卡-使用记录
  userCardDeductTicket: '/ssv-member-card/api/userCard/deductTicket', // 补扣次数
  userCardDeductDay: '/ssv-member-card/api/userCard/deductDay', // 补扣天数
  userCardStart: '/ssv-member-card/api/userCard/start', // 提前启用
  getCardUseDeductList: '/ssv-member-card/api/memberHome/getCardUseDeductList', // 获取补扣操作记录
  userCardFindShareList: '/ssv-member-card/api/userCard/findShareList', // 获取卡已有共享人列表
  userCardShare: '/ssv-member-card/api/userCard/share', // 共享

  guestList: '/operation/api/leaveMessage/list', // 用户留言列表
  guestHandle: '/operation/api/leaveMessage/deal', // 用户留言处理

  passengerStatisticsList: '/operation/orderReport/passengerFlowStatistics', // 客流量统计列表
  appointmentStatisticsList: '/operation/orderReport/reservationOrderStatistics', // 预约订单统计列表
  membercardStatisticsList: '/ssv-member-card/api/report/memberCardStatistics', // 会员卡统计列表
  storedValueCardStatisticsList: '/ssv-member-card/api/report/storageCardStatistics', // 储值卡统计列表
  exportPassenger: '/operation/orderReport/exportPassengerFlow', // 导出客流量报表
  exportMemberCard: '/operation/orderReport/exportMemberCard', // 导出会员卡报表
  exportReservationOrder: '/operation/orderReport/exportReservationOrder', // 导出预约订单报表
  exportStorageCard: '/operation/orderReport/exportStorageCard', // 导出储值卡报表

  // 1.0.7
  virtualPhysicalBind: '/operation/userCard/virtualPhysical/bind', // 制卡
  getUserInfoByPhysicalNo: '/operation/userCard/getUserInfoByPhysicalNo', // 根据实体卡获取用户信息
  gateManagerOpen: '/operation/api/gateManager/open', // 闸机开闸
  VenueProductStockQuery: '/operation/VenueProductStock/query', // 商品库存管理-库存列表
  VenueProductStockAdd: '/operation/VenueProductStock/add', // 商品库存管理-出入库
  selectVenueList: '/operation/mp/venueList/selectVenueList', // 商品库存管理-获取仓库
  venueOrderCount: '/operation/pc/statistics/venueOrderCount', // 驾驶舱-场馆订单
  businessMoneySum: '/operation/pc/statistics/businessMoneySum', // 驾驶舱-营业额
  memberCardSellCount: '/operation/pc/statistics/memberCardSellCount', // 驾驶舱-售卡数
  memberCardBuyMoney: '/operation/pc/statistics/memberCardBuyMoney', // 驾驶舱-购买金额
  venueOrderMoneySum: '/operation/pc/statistics/venueOrderMoneySum', // 驾驶舱-场馆收入分布
  venueCheckInCount: '/operation/pc/statistics/venueCheckInCount', // 驾驶舱-登记总人数
  findCheckInCountByHour: '/operation/pc/statistics/findCheckInCountByHour', // 驾驶舱-每小时客流分布
  venueProductInfoAdd: '/operation/venueProductInfo/add', // 商品管理-新增
  venueProductInfoDelete: '/operation/venueProductInfo/delete', // 商品管理-删除
  venueProductInfoPage: '/operation/venueProductInfo/page', // 商品管理-查询
  venueProductInfoUpdate: '/operation/venueProductInfo/update', // 商品管理-更新
  venueProductInfoUpDown: '/operation/venueProductInfo/upDown', // 商品管理-上下架
  venueProductStockQuery: '/operation/venueProductStock/query', // 商品库存管理-库存列表
  venueProductStockAdd: '/operation/venueProductStock/EntryOrOut', // 商品库存管理-出入库
  venueProductStockRecord: '/operation/venueProductStockRecord/query', // 商品库存管理-获取仓库
  venueProductSell: '/operation/venueProductInfo/sell', // 商品售卖-购买
  memberPersonInfoExport: '/operation/memberPersonInfo/export', // 会员个人信息管理-导出
  importMemberPersonalInfo: '/operation/memberPersonInfo/importMemberPersonalInfo', // 会员个人信息管理-导入会员信息
  templateExport: '/operation/memberPersonInfo/templateExport', // 会员个人信息管理-下载模板
  getConfig: '/operation/api/customized/getConfig', // 系统-查询参数配置
  saveConfig: '/operation/api/customized/save', // 系统-新增保存参数配置
  updateConfig: '/operation/api/customized/updateById', // 系统-修改保存参数配置
  operationGetSportsProjectListByIds: '/operation/reservateOrder/getSportsProjectListByIds', // 根据场馆id数组查询运动项目列表

  // 1.0.8
  cardUseStatistics: '/ssv-member-card/api/report/cardUseStatistics', // 报表管理-会员卡使用统计
  exportCardUseRecord: '/operation/orderReport/exportCardUseRecord', // 报表管理-会员卡使用统计-导出
  // 系统提示
  authExpireConfig: '/system/authExpire/config', // 系统提示
}
const undeCode = {
  Base64ImageUpload: window.location.protocol === 'http:' ? `/fileApi/Assets/File/ImageUpload` : 'https://luqiao.one-info.cn/fileApi/Assets/File/Base64ImageUpload', // 图片上传
}

/* function changeStr(str) {
  const arr = str.split('')
  const arr1 = arr.filter((item, index) => index % 2 === 0)
  const arr2 = arr.filter((item, index) => index % 2 === 1)
  let str1 = ''
  for (let i in arr1.length > arr2.length ? arr1 : arr2) {
    if (arr2[i] !== undefined) {
      str1 += arr2[i]
    }
    if (arr1[i] !== undefined) {
      str1 += arr1[i]
    }
  }
  return str1
} */
if (process.env.NODE_ENV === 'development') {
  Object.keys(apis).forEach((key) => {
    // apis[key] = `/apis${apis[key]}`
    // apis[key] = `${apis[key]}` // 弃用
    if (apis[key].split(":")[0] !== "http") apis[key] = `/apis${apis[key]}` // 本地单独接口调试
  })
} else {
  Object.keys(apis).forEach((key) => {
    apis[key] = `/api${apis[key]}`
  })
}
export default {
  ...apis,
  ...undeCode,
  // ImageUpload: '/fileApi/Assets/File/ImageUpload',
}
